import React, { useEffect, useRef, useState } from "react";
import SviChartService from "./SviChartService";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

const SviMiniMap = ({ site, callback }) => {
    const mapRef = useRef(null);
    const [map, setMap] = useState(null);
    // const [markerPosition, setMarkerPosition] = useState(null);

    useEffect(() => {
        if (!mapRef.current) return;

        const tahitiCoordinates = [-17.6509, -149.426]; // Latitude and longitude of Tahiti
        const mapCenter = site && site.latitude && site.longitude ? [parseFloat(site.latitude), parseFloat(site.longitude)] : tahitiCoordinates;

        const zoom = 10; // You can adjust this value as needed

        const newMap = L.map(mapRef.current).setView(mapCenter, zoom);

        L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        }).addTo(newMap);

        setMap(newMap);

        return () => {
            if (newMap) {
                newMap.remove();
            }
        };
    }, []);

    useEffect(() => {
        if (!map) return;

        const tahitiCoordinates = [-17.6509, -149.426];
        const mapCenter = site && site.latitude && site.longitude ? [parseFloat(site.latitude), parseFloat(site.longitude)] : tahitiCoordinates;

        map.setView(mapCenter, 10);

        map.eachLayer((layer) => {
            if (layer instanceof L.Marker) {
                map.removeLayer(layer);
            }
        });

        if (site && site.latitude && site.longitude) {
            const marker = L.marker(mapCenter, { draggable: true }).addTo(map);
            
            marker.on('dragend', handleMarkerDragEnd);
        }
    }, [map, site]);

    const handleMarkerDragEnd = (event) => {
        const position = event.target.getLatLng();
        // setMarkerPosition(position);
        // console.log(`Marker moved to: ${position.lat}, ${position.lng}`);

        SviChartService.updateSiteDescription(site.id, position.lat, position.lng).then(() => callback());
    };

    return (
        <>
            <div ref={mapRef} style={{ height: "100%", width: "100%", zIndex: 999 }} />            
        </>
    );
};

export default SviMiniMap;
